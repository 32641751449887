// Loaded once on initial page load
import './tools/polyfills'
import defaultJS from './default/_index.js'
import moduleJS from './modules/_index.js'

defaultJS()
moduleJS()




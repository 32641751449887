// import share from './share'
import header from './header'
import printPage from './print-page'
import cookieBar from './cookie-bar'

export default function defaultJS() {
    // fontLoader()
    // share()
    header()
    printPage()
    cookieBar()
}
export default function highlightHover() {
    const hoverGroups = document.querySelectorAll('[data-highlight-hover]')
    const activeClass = 'is-hovered'
    let links

    if (hoverGroups) {
        hoverGroups.forEach(group => {
            links = group.querySelectorAll('a')
            links.forEach(link => {
                link.addEventListener('mouseover', function() {
                    console.log('hovered link')
                    group.classList.add(activeClass)
                })
                link.addEventListener('mouseout', function() {
                    console.log('hovered off of a link')
                    group.classList.remove(activeClass)
                })
            })
        })
    }
}
import setFocus from '../tools/keyboardTrap'

export default function lightbox() {
    const lightbox = document.querySelector('[data-lightbox]')
    const lightboxTrigger = document.querySelector('[data-lightbox-trigger]')
    const lightboxCloseBtn = document.querySelector('[data-lightbox-close]')
    const body = document.querySelector('body')
    const activeClass = 'is-active'
    const activeMainClass = 'menu-is-active'

    if (lightbox) {
        lightboxTrigger.addEventListener('click', function() {
            if (lightbox.classList.contains(activeClass)) {
                lightboxClose()
            } else {
                lightboxOpen()
            }
        })

        lightboxCloseBtn.addEventListener('click', function() {
            lightboxClose()
        })

        function lightboxOpen() {
            lightbox.classList.add(activeClass)
            console.log('open lightbox')
            body.classList.add(activeMainClass)

            // Need to setup accessibility feature with non-jquery setFocus function
            // setFocus(lightbox)
        }

        function lightboxClose() {
            lightbox.classList.remove(activeClass)
            body.classList.remove(activeMainClass)
        }
    }
}
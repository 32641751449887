// Source: https://github.com/kenwheeler/slick
import $ from 'jquery'
import slick from 'slick-carousel'

// This may not work first time, need to confirm

export default function slider() {
    const $slider = document.querySelectorAll('[data-slick]')

    if ($slider.length) {
        $slider.forEach(el => {
            let type = el.getAttribute('data-slick') 
            
            if (type == 'gallery') {
                $(el).slick({
                    infinite: true,
                    fade: true,

                    // Navigation
                    dots: true,
                    arrows: true,
                    dotsClass: 'slider__dots',
                    nextArrow: `<button class="slick-next slick-arrow" aria-label="Next" type="button" class=""><svg role="img" aria-hidden="true" class="icon icon--chevron-right icon--xs"><use xlink:href="#chevron-right"></use></svg></button>`,
                    prevArrow: `<button class="slick-prev slick-arrow" aria-label="Previous" type="button" class=""><svg role="img" aria-hidden="true" class="icon icon--chevron-left icon--xs"><use xlink:href="#chevron-left"></use></svg></button>`,

                    // Grow to show taller images
                    adaptiveHeight: true
                })
            } else if (type == 'hero') {
                $(el).slick({
                    infinite: true,
                    fade: false,

                    // Navigation
                    dots: false,
                    arrows: false
                })
            } else if (type == 'lightbox') {
                $(el).slick({
                    infinite: false,
                    fade: true,

                    // Navigation
                    dots: true,
                    dotsClass: 'slider__dots slider__dots--lightbox',
                    arrows: true,
                    nextArrow: `<button class="slick-next slick-arrow" aria-label="Next" type="button" class=""><svg role="img" aria-hidden="true" class="icon icon--chevron-right icon--sm"><use xlink:href="#chevron-right"></use></svg></button>`,
                    prevArrow: `<button class="slick-prev slick-arrow" aria-label="Previous" type="button" class=""><svg role="img" aria-hidden="true" class="icon icon--chevron-left icon--sm"><use xlink:href="#chevron-left"></use></svg></button>`,
                })
            }
        })
    }
}
import player from './player'
import slider from './slider'
// import pageTransitions from './pageTransitions'
import accordions from './accordions'
import lightbox from './lightbox'
import stickyBottom from './sticky-bottom'
import highlightHover from './highlight-hover'

export default function modulesJS() {
    player()
    slider()
    lightbox()
    // pageTransitions()
    accordions()
    stickyBottom()
    highlightHover()
}
import isBreakpoint from '../tools/is-breakpoint'

export default function stickyBottom() {
    const stickyBottomEls = document.querySelectorAll('[data-sticky-bottom]')
    let stickyColEl

    if (stickyBottomEls.length) {
        stickyBottomEls.forEach(el => {
            stickyColEl = el.getBoundingClientRect()
            console.log(stickyColEl.height, window.innerHeight)

            el.style.top = `-${stickyColEl.height - window.innerHeight + 30}px`

            window.addEventListener('resize', function() {
                stickyColEl = el.getBoundingClientRect()
                console.log(stickyColEl.height, window.innerHeight)
                el.style.top = `-${stickyColEl.height - window.innerHeight + 30}px`
            })
        })
    }


    //         checkStickyElPos(el)

    //         document.addEventListener('scroll', function() {

    //             if (!isBreakpoint(900)) {
    //                 checkStickyElPos(el)
    //             } else {
    //                 if (isSticky) {
    //                     unSticky(el)
    //                 }
    //             }
    //         })

    //         window.addEventListener('resize', function() {
    //             console.log('resized window')
    //             el.style['width'] = `${el.parentNode.offsetWidth}px`

    //             if (!isBreakpoint(900)) {
    //                 checkStickyElPos(el)
    //             } else {
    //                 if (isSticky) {
    //                     unSticky(el)
    //                 }
    //             }
    //         })
    //     })
    // }

    // function checkStickyElPos(el) {
    //     bottomEdgeOfEl = getElementYOffset(el)
    //     console.log(bottomEdgeOfEl, viewportHeight + window.scrollY)

    //     if (bottomEdgeOfEl <= viewportHeight + window.scrollY) {
    //         if (!isSticky) {
    //             makeSticky(el)
    //         }
    //     } else {
    //         if (isSticky) {
    //             unSticky(el)
    //         }
    //     }
    // }

    // function makeSticky(el) {
    //     el.className += ' fixed bottom-0'
    //     isSticky = true
    //     el.style.width = `${el.parentNode.offsetWidth}px`
    // }

    // function unSticky(el) {
    //     el.classList.remove('fixed')
    //     el.classList.remove('bottom-0')
    //     el.style.width = null
    //     isSticky = false
    // }

    // Top of page
    function getElementYOffset(element) {
        let scrollOnWindow = window.pageYOffset !== undefined ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
        const rect = element.getBoundingClientRect()
        let distanceFromTopOfPage = rect.bottom
        if (scrollOnWindow !== 0) {
            distanceFromTopOfPage = rect.bottom + scrollOnWindow
        }
        
        return distanceFromTopOfPage
    }
}
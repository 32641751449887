import $ from 'jquery'
import { TweenLite } from 'gsap'

export default function accordions() {
    const $accordions = $('[data-accordions]')
    if($accordions[0]) {
        let $accordionTitle = $('[data-accordion-title]')

        // Accordion trigger
        $accordionTitle.on('click', function(e) {
            const $targetAccordion = $(this).next()

            // Already open, so close it
            if ($(this).parent().prop('open')) {
                closeAccordion($targetAccordion, $(this))

            // Open accordion
            } else {
                // closeAllAccordions()
                openAccordion($targetAccordion, $(this))
            }

        })

        // Open accordion function
        function openAccordion($target, $title) {
            TweenLite.set($target, {
                height: 'auto',
                visibility: 'visible'
            })
            TweenLite.from($target, 0.3, {height:0})
            $title.attr('aria-expanded','true')
        }


        // Close accordion function
        function closeAccordion($target, $title) {
            $title.attr('aria-expanded','false')
            $target.css({
                'height':'',
                'visibility':''
            })
        }

        function closeAllAccordions() {
            $accordionTitle.attr('aria-expanded','false')
            $('.accordion__body').css({
                'height':'',
                'visibility':''
            })
            $('.accordion').removeAttr('open')
        }

        closeAllAccordions()
    }
}
import $ from 'jquery'
import isBreakpoint from '../tools/is-breakpoint'
import setFocus from '../tools/keyboardTrap'

/*
    Manage open/close states for header menu, search and basket.
*/

export default function header() {
    // Elements
    const $toggle = $('[data-header-toggle]')
    const $reveal = $('[data-header-reveal]')
    const $close = $('[data-header-close]')
    const $main = $('#main')
    const $body = $('body')

    // Classes
    const activeClass = 'is-active'
    const activeMainClass = 'menu-is-active'

    // Pre-defined vars
    let $drawer
    let target
    let delay = 0

    $toggle.on('click', function(e) {
        e.stopImmediatePropagation()

        // Get target reveal
        target = $(this).attr('data-header-toggle')
        $drawer = $(`[data-header-reveal=${target}]`)
        
        if (target == 'cart') {
            if (!isBreakpoint(600)) {
                // Show JS cart reveal on desktop only, link to Cart page on mobile
                e.preventDefault()
                toggleReveal($drawer, target)
            }
        } else {
            toggleReveal($drawer, target)
        }
    })

    // Toggle function
    function toggleReveal($drawer, target) {
        // Hide targeted reveal if it's open
        if ($drawer.hasClass(activeClass)) {
            closeReveal($drawer, target)

        // Show targeted reveal and hide any other open ones
        } else {
            showReveal($drawer, target)
        }
    }

    // Show function
    function showReveal($drawer, target) {
        if ($reveal.hasClass(activeClass)) {
            $('[data-header-reveal]').not(`.${target}`).removeClass(activeClass)
            $('[data-header-toggle]').not(`.${target}`).removeClass(activeClass)
            delay = 200
        }
        
        setTimeout(function() {
            $drawer.addClass(activeClass)
            $main.addClass(activeMainClass)
            $body.addClass(activeMainClass)
            $(`[data-header-toggle=${target}]`).addClass(activeClass)
            setFocus($drawer)
        }, delay)
    }

    // Close function
    function closeReveal($drawer, target) {
        $drawer.removeClass(activeClass)
        $main.removeClass(activeMainClass)
        $body.removeClass(activeMainClass)
        $(`[data-header-toggle=${target}]`).trigger('focus')
        $(`[data-header-toggle=${target}]`).removeClass(activeClass)
    }

    // Close drawer with esc
    $(document).on('keydown', function(e) {
        if (e.key === 'Escape') {
            if ($reveal.hasClass(activeClass)) {
                $drawer = $('[data-header-reveal].is-active')
                target = $drawer.attr('data-header-reveal')

                closeReveal($drawer, target)
            }
        }
    })

    // Close drawer with body click
    $(document).on('click', function() {
        $drawer = $('[data-header-reveal].is-active')
        target = $drawer.attr('data-header-reveal')
        closeReveal($drawer, target)
    })
    $reveal.on('click', function(e) {
        e.stopPropagation()
        //return false
    })
    
    // Accessible close button
    $close.on('click', function() {
        $drawer = $('[data-header-reveal].is-active')
        target = $drawer.attr('data-header-reveal')
        
        $drawer = $(this).closest('[data-header-reveal]')
        target = $drawer.attr('data-header-reveal')

        closeReveal($drawer, target)
    })
}